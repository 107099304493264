import React, { Component } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import {Helmet} from "react-helmet";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";

import avatar from './assets/avatar.jpg';

// import logos from './assets/logos.svg';
import ehdd from './assets/site-ehdd.jpg';
import ybca from './assets/site-ybca.jpg';
import bora from './assets/site-bora.jpg';
import deltaway from './assets/site-deltaway.jpg';
import chavon from './assets/site-chavon.jpg';
import lines from './assets/site-lines.jpg';
import ftmason from './assets/site-ftmason.jpg';
import giants from './assets/site-giants.jpg';
import bionic from './assets/site-bionic.jpg';

import att from './assets/logo-att.png';
import google from './assets/logo-google.png';
import mastercard from './assets/logo-mastercard.png';
import citi from './assets/logo-citi.png';
import pfizer from './assets/logo-pfizer.png';
import citrix from './assets/logo-citrix.png';
import bayer from './assets/logo-bayer.png';
import gsk from './assets/logo-gsk.png';
import sanford from './assets/logo-sanford.png';
import sf from './assets/logo-giants.png';
import asu from './assets/logo-asu.png';
import davidson from './assets/logo-davidson.png';
import videoposter from './assets/video-poster.jpg';

import ekko from './assets/startup-ekko.png';
import seshn from './assets/startup-seshn.png';

import gmixr from './assets/my-logos-gmixr.png';
import squeezewatch from './assets/my-logos-squeeze-watch.png';
import trample from './assets/my-logos-trample-the-earth.png';
import whenwaves from './assets/my-logos-when-waves.png';
import ostfap from './assets/my-logos-ost-fap.png';

import './App.scss'
import './App.scss'

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      marginMultiply:0,
      index: 0,
      formOpen: false
    }
    this.setMouseOver = this.setMouseOver.bind(this);
    this.setMouseOut = this.setMouseOut.bind(this);
    this.openForm = this.openForm.bind(this);

  }

  setMouseOver(){
    this.setState({
      marginMultiply: this.state.index === 0 ? 3 : 2
    })
  }

  setMouseOut(){
    this.setState({
      marginMultiply: 0
    })
  }

  openForm(){
    this.setState({
      formOpen: true
    })
  }

  onLeave(origin, destination, direction) {
    this.setState({
      index:destination.index,
      marginMultiply: (destination.index === 0) ? 0 : 2
    })
    // console.log('onLeave', { origin, destination, direction });
    // arguments are mapped in order of fullpage.js callback arguments do something
    // with the event
  }

  render() {
    //const pos = ['50% 50%'];
    const positions = ['50% 50%','0% 0%','100% 0','0% 100%','0% 0%'];
    let position = positions[this.state.index]
    let svgs = [-7,-6,-5,-4,-3,-2,-1,0,1,2,3,4,5,6].map((i,j) => {

      
      return (
        <svg className="logo" width="214.4px" height="195.6px" viewBox="0 0 214.4 195.6" style={{transformOrigin:position,left:i*this.state.marginMultiply+'px',transform:'rotate('+(i*this.state.marginMultiply)+'deg)'}}>
            <g className="st0">
              <path className="st1" d="M138.9,43.3c11.7,0,21.2,1.7,28.5,5.2s13,7.8,17.1,13.1c4.1,5.3,6.9,11,8.4,17.1c1.5,6.2,2.3,11.9,2.3,17.3
                v37.3c0,4.7,0.8,8,2.5,10c1.6,2,4.8,3,9.5,3h7.2v49.3h-91.9v-84.9c0-3.5-0.4-6.2-1.3-8.3c-0.8-2.1-1.9-3.7-3.2-4.8
                c-1.3-1.1-2.7-1.8-4.3-2.2c-1.6-0.4-3.1-0.5-4.6-0.5c-5.8,0-10.1,1.7-12.9,5c-2.8,3.4-4.2,8.1-4.2,14.3v19c0,4.7,0.8,8,2.5,10
                c1.6,2,4.8,3,9.5,3h9.8v49.3H0v-49.3h7.8c3.6,0,6.4-1.2,8.3-3.5c1.9-2.3,2.9-5.2,2.9-8.4l-0.3-70.1c0-5.1-1.6-8.9-4.8-11.4
                c-3.2-2.4-7-3.7-11.2-3.7H0V0h91.9v49.3c0,1.7-0.5,4.6-1.6,8.8c-1.1,4.2-3.1,8.3-6,12.5l2.7,1.3c4.8-8.7,11.2-15.6,19.3-20.8
                C114.3,45.9,125.2,43.3,138.9,43.3z"/>
            </g>
          </svg>
      )
    });
    // let divs = [...Array(14).keys()].map(i => {
    //   return (
    //     <div />
    //   )
    // });
    // const greetings = ['hello','bonjour','hola','guten tag','ciao','olà','namaste','salaam'];
    // let greeting = greetings[Math.floor((Math.random()*greetings.length))]

    const anchors = ["home", "stacks", "clients", "projects", "startups", "contact", "after-dark", "shhh-secret", "3dvideo"];
    let nextLink = (this.state.index === 7) ? 'home' : anchors[this.state.index + 1]

    return (
      <div className="App">
        <Helmet>
            <title>Hanusek | Lead Software Engineer with over 15 years of experience in the complete product development lifecycle</title>
            <meta name="description" content="Lead Software Engineer, Javascript Programmer, Delight Programmer, UI/UX, User Experience, User Interface, Interaction Design, Usability" />
        </Helmet>
        <header className={((this.state.index === 0 || this.state.index === 6) ? 'home' : 'away') + (this.state.index > 5 ? ' dark' : '' )}>
          <div className="content-wrap" onMouseOver={this.setMouseOver} onMouseOut={this.setMouseOut}>
            <a data-role="none" href={'#'+nextLink} className="logo-wrap">
              {svgs}
            </a>
            <div className="avatar" style={{backgroundImage: 'url('+avatar+')'}} />
            <div className="hello"><strong>{(this.state.index > 4 ? ' after dark' : 'hello' )}</strong></div>
            <div className="bio" dangerouslySetInnerHTML={{__html: (this.state.index > 4 ? ' late nights working on god knows what. a collection of after hours projects.<br /><br /><strong>click to proceed</strong>' : '<h1>john hanusek</h1>Software Development Engineer<br />of Frontend Experiences<br /><br /><strong>click to proceed</strong>' )}} />
          </div>
        </header>
        <ReactFullpage
            anchors={anchors}
          onLeave={this.onLeave.bind(this)}
          navigationTooltips={anchors}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section">
                  
                </div>
                <div className="section stacks">
                  <div className="text">
                    <h3 className="space-below">stacks through history</h3>
                    <div className="row">
                      <div className="col">
                        <h4>1974 - 1994</h4>
                        <ul>
                          <li><a href="https://en.wikipedia.org/wiki/BASIC" target="_blank" rel="noopener noreferrer">BASIC</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Atari" target="_blank" rel="noopener noreferrer">Atari</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Commodore_64" target="_blank" rel="noopener noreferrer">Commodore 64</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Intellivision" target="_blank" rel="noopener noreferrer">Intellivision</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Nintendo" target="_blank" rel="noopener noreferrer">Nintendo (NES)</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Windows_3.1x" target="_blank" rel="noopener noreferrer">Windows 3.1x</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Intel_80486" target="_blank" rel="noopener noreferrer">Intel 80486</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Compaq_Portable" target="_blank" rel="noopener noreferrer">Compaq Portable</a></li>
                        </ul>
                      </div>
                      <div className="col">
                        <h4>1995 - 1998</h4>
                        <ul>
                          <li><a href="https://en.wikipedia.org/wiki/Adobe_Photoshop" target="_blank" rel="noopener noreferrer">Adobe Photoshop</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Adobe_Illustrator" target="_blank" rel="noopener noreferrer">Adobe Illustrator</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Adobe_Premiere_Pro" target="_blank" rel="noopener noreferrer">Adobe Premiere</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Adobe_Director" target="_blank" rel="noopener noreferrer">Macromedia Director (Lingo)</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Media_100" target="_blank" rel="noopener noreferrer">Media 100</a> / <a href="https://en.wikipedia.org/wiki/Avid_Technology" target="_blank" rel="noopener noreferrer">Avid</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/HTML" target="_blank" rel="noopener noreferrer">HTML</a>/<a href="https://en.wikipedia.org/wiki/Cascading_Style_Sheets" target="_blank" rel="noopener noreferrer">CSS</a></li>
                        </ul>
                      </div>
                      <div className="col">
                        <h4>1998 - 2003</h4>
                        <ul>
                          <li><a href="https://en.wikipedia.org/wiki/Adobe_Flash" target="_blank" rel="noopener noreferrer">Adobe Flash</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Adobe_Dreamweaver" target="_blank" rel="noopener noreferrer">Adobe Dreamweaver</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Adobe_After_Effects" target="_blank" rel="noopener noreferrer">Adobe After Effects</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/MySQL" target="_blank" rel="noopener noreferrer">MySQL</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/PHP" target="_blank" rel="noopener noreferrer">PHP</a></li>
                        </ul>
                      </div>
                      <div className="col">
                        <h4>2003 - 2010</h4>
                        <ul>
                          <li><a href="https://en.wikipedia.org/wiki/JavaScript" target="_blank" rel="noopener noreferrer">Javascript</a>/<a href="https://en.wikipedia.org/wiki/JQuery" target="_blank" rel="noopener noreferrer">jQuery</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Adobe_Flash" target="_blank" rel="noopener noreferrer">Adobe Flash</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/WordPress" target="_blank" rel="noopener noreferrer">Wordpress</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/PHP" target="_blank" rel="noopener noreferrer">PHP</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/ASP.NET" target="_blank" rel="noopener noreferrer">ASP.NET</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/C_Sharp_(programming_language)" target="_blank" rel="noopener noreferrer">C#</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Cinema_4D" target="_blank" rel="noopener noreferrer">Cinema 4D</a></li>
                        </ul>  
                      </div>
                      <div className="col">
                        <h4>2010 - 2015</h4>
                        <ul>
                          <li><a href="https://en.wikipedia.org/wiki/JavaScript" target="_blank" rel="noopener noreferrer">Javascript</a>/<a href="https://en.wikipedia.org/wiki/Node.js" target="_blank" rel="noopener noreferrer">Node.js</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Python_(programming_language)" target="_blank" rel="noopener noreferrer">Python</a>, <a href="https://en.wikipedia.org/wiki/Django_(web_framework)" target="_blank" rel="noopener noreferrer">Django</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Objective-C" target="_blank" rel="noopener noreferrer">Objective-C</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Postgres" target="_blank" rel="noopener noreferrer">Postgres</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/MongoDB" target="_blank" rel="noopener noreferrer">MongoDB</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Gulp.js" target="_blank" rel="noopener noreferrer">Gulp.js</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Less_(stylesheet_language)" target="_blank" rel="noopener noreferrer">Less</a></li>
                        </ul>  
                      </div>
                      <div className="col">
                        <h4>2015 - today</h4>
                        <ul>
                        <li><a href="https://en.wikipedia.org/wiki/JavaScript" target="_blank" rel="noopener noreferrer">Javascript</a>/<a href="https://en.wikipedia.org/wiki/TypeScript" target="_blank" rel="noopener noreferrer">TypeScript</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Swift_(programming_language)" target="_blank" rel="noopener noreferrer">Swift</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/React_(web_framework)" target="_blank" rel="noopener noreferrer">React.js</a>, <a href="https://en.wikipedia.org/wiki/Vue.js" target="_blank" rel="noopener noreferrer">Vue.js</a>, <a href="https://en.wikipedia.org/wiki/Svelte" target="_blank" rel="noopener noreferrer">Svelte</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Jest_(JavaScript_framework)" target="_blank" rel="noopener noreferrer">Jest</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/GraphQL" target="_blank" rel="noopener noreferrer">GraphQL</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Sass_(stylesheet_language)" target="_blank" rel="noopener noreferrer">SCSS</a></li>
                          <li><a href="https://en.wikipedia.org/wiki/Webpack" target="_blank" rel="noopener noreferrer">Webpack</a></li>
                        </ul>  
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section clients">
                  <div className="text">
                    <h3 className="space-below">clients i've worked for</h3>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img className="logos" src={att} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>motion design, hud animations, sound engineering and editing</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <img className="logos" src={google} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>marketing site for one of google's new development platforms called bebop.co</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <img className="logos" src={mastercard} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>flash applications for mastercard priceless campaign</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <img className="logos" src={citi} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>motion design for a business summit</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img className="logos" src={pfizer} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>after effect animations for new product</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <img className="logos" src={citrix} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>motion design for new campaign</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <img className="logos" src={bayer} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>touch screen app for conference, motion design, animation</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <img className="logos" src={gsk} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>AR 3D element animation and development</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img className="logos" src={sanford} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>touch screen app for searching clinical trials</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <img className="logos" src={sf} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>marketing site for business division</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <img className="logos" src={asu} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>flash application and maintenance . it was a long time ago</p>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <img className="logos" src={davidson} alt="client-logos" />
                      <div className="text">
                        <div>
                          <p>wordpress site for showcasing leadership</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section sites">
                  <div className="text">
                    <h3 className="space-below">projects i'm proud to show</h3>
                  </div>
                  <div className="row">
                    <div className="col">
                      <a href="https://ehdd.com" target="_blank" rel="noopener noreferrer" >
                        <img src={ehdd} alt="ehdd" /><br />
                        <div className="text">
                          <div>
                            <h3>ehdd.com</h3>
                            <p>react | gatsby.js | netlify | headless wordpress</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col">
                      <a href="https://bora.co" target="_blank" rel="noopener noreferrer" >
                        <img src={bora} alt="bora" /><br />
                        <div className="text">
                          <div>
                            <h3>bora.co</h3>
                            <p>wordpress | jquery | google maps api</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col">
                      <a href="http://bioniclandscape.com/" target="_blank" rel="noopener noreferrer" >
                        <img src={bionic} alt="bionic" /><br />
                        <div className="text">
                          <div>
                            <h3>bioniclandscape.com</h3>
                            <p>react | react router | headless wordpress | vimeo api</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <a href="https://linesballet.org" target="_blank" rel="noopener noreferrer" >
                        <img src={lines} alt="lines" /><br />
                        <div className="text">
                          <div>
                            <h3>linesballet.org</h3>
                            <p>wordpress | custom event calendar | mindbody api</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col">
                      <a href="https://chavon.edu.do" target="_blank" rel="noopener noreferrer" >
                        <img src={chavon} alt="chavon" /><br />
                        <div className="text">
                          <div>
                            <h3>chavon.edu.do</h3>
                            <p>wordpress | jquery | bilingual</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col">
                      <a href="http://ybca.org/" target="_blank" rel="noopener noreferrer" >
                        <img src={ybca} alt="ybca" /><br />
                        <div className="text">
                          <div>
                            <h3>ybca.org</h3>
                            <p>wordpress | jquery | custom ux/ui | custom calendar</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <a href="http://deltawayenergy.com" target="_blank" rel="noopener noreferrer" >
                        <img src={deltaway} alt="deltaway" /><br />
                        <div className="text">
                          <div>
                            <h3>deltawayenergy.com</h3>
                            <p>wordpress | jquery | custom calculator</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col">
                      <a href="https://fortmason.org" target="_blank" rel="noopener noreferrer" >
                        <img src={ftmason} alt="julesapp" /><br />
                        <div className="text">
                          <div>
                            <h3>fortmason.org</h3>
                            <p>wordpress | jquery | custom event calendar | fullcalendar.js api</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col">
                      <a href="https://giantsenterprises.com/" target="_blank" rel="noopener noreferrer" >
                        <img src={giants} alt="form4" /><br />
                        <div className="text">
                          <div>
                            <h3>giantsenterprises.com</h3>
                            <p>wordpress | jquery | slick slider api</p>
                          </div>
                        </div>
                      </a>
                    </div>
              
                  </div>
                </div>
                <div className="section clients">
                  <div className="text">
                    <h3 className="space-below">Startup Work</h3>
                  </div>
                  <div className="row">
                  <div className="col">
                      <img className="logos" src={seshn} alt="startup-seshn" />
                      <a href="https://www.crunchbase.com/organization/seshn" target="_blank" rel="noopener noreferrer">
                      <div className="text">
                        <div>
                          <h3>CTO (2012 - 2014)</h3>
                          <p>Seshn is a Realtime Social Webzine Powered by Unique People &amp; Their Passions.</p>
                        </div>
                      </div>
                      </a>
                    </div>
                    <div className="col">
                    <a href="https://www.crunchbase.com/organization/ekko-inc" target="_blank" rel="noopener noreferrer">

                      <img className="logos" src={ekko} alt="startup-ekko" />
                      <div className="text">
                        <div>
                          <h3>Lead UX/UI Developer (2014 - 2016)</h3>
                          <p></p>
                          <p>Ekko is a secure messaging platform for bringing together and organizing your emails, social messaging, and content apps.</p>
                        </div>
                      </div>
                      </a>
                    </div>
                   
                  </div>
                </div>
                <div className="section contact">
                  <div className="text">
                    <h3>contact</h3>
                    <p>have a project that needs help? Looking to get a product shipped? I may be able to help.</p>
                    <p>email me: <a href="mailto:john@hanusek.com">john@hanusek.com</a></p>
                    <div className="social">
                      <a href="https://github.com/jcnh74" target="_blank" rel="noopener noreferrer">
                        <svg width="53.1px" height="51.8px" viewBox="0 0 53.1 51.8">
                        <g>
                          <path  d="M0.3,22.5c-2,13.5,6.1,25.3,17.9,29.2c1.3,0.2,1.8-0.6,1.8-1.3c0-0.6,0-2.7,0-4.9c-6.7,1.2-8.4-1.6-8.9-3.1
                            c-0.3-0.8-1.6-3.1-2.7-3.7c-0.9-0.5-2.3-1.7,0-1.8c2.1,0,3.6,1.9,4.1,2.7c2.4,4,6.2,2.9,7.7,2.2c0.2-1.7,0.9-2.9,1.7-3.5
                            c-5.9-0.7-12.1-3-12.1-13.1c0-2.9,1-5.3,2.7-7.1c-0.3-0.7-1.2-3.4,0.3-7c0,0,2.2-0.7,7.3,2.7c2.1-0.6,4.4-0.9,6.6-0.9
                            c2.3,0,4.5,0.3,6.6,0.9c5.1-3.5,7.3-2.7,7.3-2.7c1.5,3.6,0.5,6.4,0.3,7c1.7,1.9,2.7,4.2,2.7,7.1c0,10.2-6.2,12.4-12.1,13.1
                            c1,0.8,1.8,2.4,1.8,4.9c0,3.5,0,6.4,0,7.3c0,0.7,0.5,1.5,1.8,1.3c10.5-3.5,18.1-13.5,18.1-25.2c0-16.1-14.3-28.8-30.8-26.2
                            C10.9,2.1,2,11.1,0.3,22.5z"/>
                        </g>
                        </svg>
                      </a>
                      <a href="https://www.linkedin.com/in/johnhanusek/" target="_blank" rel="noopener noreferrer">
                        <svg width="51.9px" height="51.9px" viewBox="0 0 51.9 51.9">
                          <g>
                            <g id="post-linkedin_1_">
                              <path d="M46.7,0H5.2C2.3,0,0,2.3,0,5.2v41.5c0,2.9,2.3,5.2,5.2,5.2h41.5c2.9,0,5.2-2.3,5.2-5.2V5.2C51.9,2.3,49.6,0,46.7,0z
                                M15.6,44.1H7.8V20.8h7.8V44.1z M11.7,16.3c-2.6,0-4.7-2.1-4.7-4.7S9.1,7,11.7,7s4.7,2.1,4.7,4.7S14.3,16.3,11.7,16.3z M44.1,44.1
                                h-7.8V30.4c0-2.1-1.8-3.9-3.9-3.9s-3.9,1.8-3.9,3.9v13.8h-7.8V20.8h7.8v3.1c1.3-2.1,4.2-3.6,6.5-3.6c4.9,0,9.1,4.2,9.1,9.1V44.1z"
                                />
                            </g>
                          </g>
                        </svg>
                      </a>
                      <a href="https://www.hackerrank.com/hanusek" target="_blank" rel="noopener noreferrer">
                        <svg width="44.7px" height="49.3px" viewBox="0 0 44.7 49.3">
                        <path d="M44.1,13.6c0-0.1,0-0.2,0-0.3c-0.1-0.6-0.4-1.2-0.9-1.6c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2c-3-2.2-6.1-4.2-9.3-6.1
                          c-3.2-1.9-6.6-3.5-10-5c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.6-0.2-1.2-0.2-1.8,0c0,0-0.1,0-0.1,0.1c-6.8,3-13.3,6.7-19.3,11.1
                          c-0.1,0.1-0.2,0.2-0.4,0.3l0,0c-0.5,0.4-0.8,1-0.9,1.6c0,0.1,0,0.2,0,0.3c-0.8,7.4-0.8,14.9,0,22.3c0.1,0.8,0.5,1.4,1.1,1.9
                          c0.1,0.1,0.1,0.1,0.2,0.1c3,2.2,6.1,4.2,9.3,6.1c3.2,1.9,6.6,3.5,10,5c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0.7,0.3,1.4,0.3,2.1,0
                          c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1c3.4-1.5,6.7-3.2,10-5c3.2-1.8,6.3-3.9,9.3-6.1c0.1,0,0.1-0.1,0.2-0.1c0.6-0.5,1-1.1,1.1-1.9
                          c0.4-3.7,0.6-7.5,0.6-11.2C44.7,21,44.5,17.3,44.1,13.6z M32.5,35.2L29,38.4c-0.2,0.2-0.6,0.2-0.8,0l-3.4-3.2
                          c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.3,0.3-0.6,0.6-0.6H26v-6.9h-7.4V35c0,0.3-0.2,0.6-0.6,0.6H14c-0.3,0-0.6-0.2-0.6-0.6V15.1h-0.8
                          c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.6,0-0.8l3.4-3.2c0.2-0.2,0.6-0.2,0.8,0l3.5,3.2c0.1,0.1,0.2,0.3,0.2,0.4
                          c0,0.3-0.3,0.6-0.6,0.6h-0.8V22H26v-7.7c0-0.3,0.2-0.6,0.6-0.6h4.1c0.3,0,0.6,0.2,0.6,0.6v19.9h0.8c0.2,0,0.3,0.1,0.4,0.2
                          C32.7,34.6,32.7,35,32.5,35.2z"/>
                        </svg>
                      </a>
                      <a href="https://angel.co/jcnh74" target="_blank" rel="noopener noreferrer">
                        <svg width="59.2px" height="55.1px" viewBox="0 0 59.2 55.1">
                        <g>
                          <path d="M42,23.6c2.3,0.3,3.8,1.4,4.7,2.8c0.9,1.4,1.4,3.8,1.4,7c0,6.3-1.9,11.5-5.8,15.5s-8.7,6.1-14.7,6.1
                            c-2.3,0-4.5-0.3-6.8-1.2c-2.3-0.9-4-2.1-5.6-3.5c-1.7-1.6-3.1-3.5-4-5.2c-0.7-1.9-1.2-3.8-1.2-5.8c0-2.1,0.5-3.8,1.4-5.1
                            c0.9-1.2,2.4-1.9,4.4-2.4c-0.3-0.9-0.7-1.6-0.9-2.3c-0.2-0.5-0.3-1-0.3-1.4c0-1,0.5-2.3,1.7-3.5c1.2-1.2,2.3-1.7,3.3-1.7
                            c0.5,0,0.9,0,1.4,0.2c0.5,0.2,1,0.3,1.7,0.9c-1.6-5.2-3.1-9.6-4-12.6c-0.9-3-1.2-4.9-1.2-6.1c0-1.6,0.3-2.8,1.2-3.7
                            c0.7-0.9,1.9-1.4,3.3-1.4c2.3,0,5.2,5.2,8.9,15.9c0.7,1.7,1,3.1,1.4,4.2c0.3-0.7,0.7-1.9,1.2-3.3c3.7-10.5,6.8-15.7,9.2-15.7
                            c1.2,0,2.3,0.3,3.1,1.2c0.7,0.9,1.2,2.1,1.2,3.5c0,1-0.3,3.1-1.2,6.1C45,15.1,43.8,18.9,42,23.6L42,23.6z M15.1,39
                            c0.3,0.3,0.9,1,1.4,1.9c1.6,2.3,3.1,3.5,4.5,3.5c0.5,0,0.9-0.2,1.2-0.5s0.5-0.7,0.5-0.9c0-0.3-0.2-1-0.7-1.9
                            c-0.5-0.9-1.2-1.9-2.1-3c-1-1.2-1.9-2.3-2.4-2.8c-0.7-0.5-1.2-0.9-1.6-0.9c-0.9,0-1.7,0.5-2.4,1.4c-0.7,0.9-1,2.1-1,3.3
                            c0,1,0.2,2.1,0.7,3.5c0.5,1.2,1.2,2.6,2.3,3.8c1.6,1.7,3.3,3.3,5.6,4.4c2.3,1,4.5,1.6,7.3,1.6c4.9,0,8.9-1.7,12.2-5.4
                            c3.3-3.7,4.9-8.2,4.9-13.8c0-1.7-0.2-3-0.3-4s-0.7-1.7-1.2-2.1c-1-0.9-3-1.6-5.9-2.3c-3-0.7-6.1-1-9.2-1c-0.9,0-1.6,0.2-1.9,0.5
                            c-0.3,0.3-0.5,0.9-0.5,1.6c0,1.7,0.9,3,2.8,3.7c1.9,0.7,5.1,1.2,9.2,1.2h1.6c0.3,0,0.7,0.2,0.9,0.3c0.2,0.3,0.3,0.7,0.3,1.2
                            c-0.3,0.3-1.2,0.9-2.6,1.4s-2.3,1-3,1.6c-1.6,1-2.8,2.4-3.7,4c-0.9,1.6-1.4,3-1.4,4.4c0,0.9,0.2,1.7,0.5,3c0.3,1.2,0.5,1.9,0.5,2.1
                            v0.3v0.3c-1,0-1.9-0.7-2.6-1.9c-0.7-1.2-0.9-2.8-0.9-4.9v-0.3c-0.2,0.2-0.3,0.3-0.5,0.3c-0.2,0-0.3,0.2-0.7,0.2h-0.7
                            c-0.2,0-0.3-0.2-0.7-0.2c0,0.3,0.2,0.5,0.2,0.9v0.7c0,0.9-0.3,1.7-1,2.4c-0.7,0.7-1.6,1-2.6,1c-1.6,0-3.1-0.7-4.9-2.3
                            c-1.6-1.6-2.4-3-2.4-4.5c0-0.3,0-0.5,0.2-0.7C14.8,39.3,15,39.2,15.1,39L15.1,39z M26.5,39.9c0.3,0,0.9-0.2,1.2-0.5
                            c0.3-0.3,0.5-0.9,0.5-1.2c0-0.5-0.3-1.6-1-3.3c-0.7-1.7-1.6-3.5-2.6-5.1c-0.7-1.2-1.6-2.3-2.3-2.8c-0.7-0.7-1.4-0.9-2.1-0.9
                            c-0.5,0-1,0.3-1.7,1c-0.7,0.7-0.9,1.2-0.9,1.9c0,0.5,0.3,1.6,0.9,2.8c0.7,1.2,1.4,2.4,2.4,3.8c1,1.4,2.1,2.6,3.1,3.5
                            C25.1,39.5,25.8,39.9,26.5,39.9L26.5,39.9z M30.1,21.7L26,10c-1-3-1.7-5.1-2.4-5.9c-0.5-0.9-1-1.4-1.7-1.4c-0.5,0-0.9,0.2-1.2,0.5
                            c-0.5,0.5-0.7,1-0.7,1.7c0,1.2,0.5,3.1,1.4,5.9s2.3,6.6,4,11.2c0.2-0.3,0.3-0.5,0.7-0.5c0.3-0.2,0.7-0.2,1-0.2h0.9
                            C28.4,21.5,29.1,21.5,30.1,21.7L30.1,21.7z M34.3,33.1c-1,0-2.1-0.2-3.1-0.3c-1-0.2-1.9-0.3-2.8-0.7c0.3,0.9,0.7,1.6,1,2.4
                            c0.3,0.9,0.5,1.6,0.7,2.4c0.5-0.7,1.2-1.4,1.9-2.1C32.9,34.1,33.6,33.6,34.3,33.1L34.3,33.1z M39.4,22.9c1.7-4.5,3-8.4,4-11.3
                            c0.9-3,1.4-4.7,1.4-5.4S44.6,5,44.3,4.4c-0.3-0.3-0.7-0.5-1.2-0.5c-0.7,0-1.4,0.5-2.1,1.7s-1.6,3-2.4,5.6l-3.8,10.8L39.4,22.9
                            L39.4,22.9z"/>
                        </g>
                        </svg>
                      </a>
                      <a href="https://twitter.com/hanusek" target="_blank" rel="noopener noreferrer">
                        <svg width="58.5px" height="47.5px" viewBox="0 0 58.5 47.5">
                          <g>
                            <g id="layer1_2_" transform="translate(-539.17946,-568.85777)">
                              <path  d="M557.6,616.4c22.1,0,34.1-18.3,34.1-34.1c0-0.5,0-1,0-1.6c2.3-1.7,4.4-3.8,6-6.2
                                c-2.1,1-4.5,1.6-6.9,1.9c2.5-1.5,4.4-3.8,5.3-6.6c-2.3,1.4-4.9,2.4-7.6,2.9c-2.2-2.3-5.3-3.8-8.8-3.8c-6.6,0-12,5.4-12,12
                                c0,0.9,0.1,1.9,0.3,2.7c-10-0.5-18.8-5.3-24.7-12.5c-1,1.8-1.6,3.8-1.6,6c0,4.2,2.1,7.8,5.3,10c-2-0.1-3.8-0.6-5.4-1.5
                                c0,0.1,0,0.1,0,0.2c0,5.8,4.1,10.7,9.6,11.8c-1,0.3-2.1,0.4-3.2,0.4c-0.8,0-1.5-0.1-2.3-0.2c1.5,4.8,6,8.2,11.2,8.3
                                c-4.1,3.2-9.3,5.1-14.9,5.1c-1,0-1.9-0.1-2.9-0.2C544.5,614.4,550.8,616.4,557.6,616.4"/>
                            </g>
                          </g>
                        </svg>
                      </a>
                      <a href="https://instagram.com/johnnyhanusek/" target="_blank" rel="noopener noreferrer">
                        <svg width="52.3px" height="52.3px" viewBox="0 0 52.3 52.3" >
                        <g>
                          <path  d="M26.1,0c-7.1,0-8,0-10.8,0.2C12.6,0.3,10.7,0.7,9,1.4C7.3,2,5.8,2.9,4.4,4.4C2.9,5.8,2,7.3,1.4,9c-0.6,1.7-1.1,3.6-1.2,6.3
                            C0,18.1,0,19,0,26.1c0,7.1,0,8,0.2,10.8c0.1,2.8,0.6,4.7,1.2,6.3c0.7,1.7,1.6,3.2,3,4.6c1.5,1.5,2.9,2.3,4.6,3
                            c1.7,0.6,3.6,1.1,6.3,1.2c2.8,0.1,3.7,0.2,10.8,0.2c7.1,0,8,0,10.8-0.2c2.8-0.1,4.7-0.6,6.3-1.2c1.7-0.7,3.2-1.6,4.6-3
                            c1.5-1.5,2.3-2.9,3-4.6c0.6-1.7,1.1-3.6,1.2-6.3c0.1-2.8,0.2-3.7,0.2-10.8c0-7.1,0-8-0.2-10.8c-0.1-2.8-0.6-4.7-1.2-6.3
                            c-0.7-1.7-1.6-3.2-3-4.6c-1.5-1.5-2.9-2.3-4.6-3c-1.7-0.6-3.6-1.1-6.3-1.2C34.1,0,33.2,0,26.1,0L26.1,0L26.1,0z M26.1,4.7
                            c7,0,7.8,0,10.6,0.2c2.5,0.1,3.9,0.5,4.9,0.9c1.2,0.5,2.1,1,3,2c0.9,0.9,1.5,1.8,2,3c0.4,0.9,0.8,2.3,0.9,4.9
                            c0.1,2.8,0.2,3.6,0.2,10.6c0,7,0,7.8-0.2,10.6c-0.1,2.5-0.5,3.9-0.9,4.9c-0.5,1.2-1,2.1-2,3c-0.9,0.9-1.8,1.5-3,2
                            c-0.9,0.4-2.3,0.8-4.9,0.9c-2.8,0.1-3.6,0.2-10.6,0.2c-7,0-7.8,0-10.6-0.2c-2.5-0.1-3.9-0.5-4.9-0.9c-1.2-0.5-2.1-1-3-2
                            c-0.9-0.9-1.5-1.8-2-3c-0.4-0.9-0.8-2.3-0.9-4.9c-0.1-2.8-0.2-3.6-0.2-10.6c0-7,0-7.8,0.2-10.6C5,13,5.4,11.6,5.8,10.7
                            c0.5-1.2,1-2.1,2-3c0.9-0.9,1.8-1.5,3-2C11.6,5.4,13,5,15.6,4.9C18.3,4.7,19.2,4.7,26.1,4.7"/>
                          <path  d="M26.1,34.8c-4.8,0-8.7-3.9-8.7-8.7c0-4.8,3.9-8.7,8.7-8.7c4.8,0,8.7,3.9,8.7,8.7C34.8,30.9,30.9,34.8,26.1,34.8L26.1,34.8z
                            M26.1,12.7c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4c7.4,0,13.4-6,13.4-13.4C39.5,18.7,33.5,12.7,26.1,12.7L26.1,12.7z"/>
                          <path  d="M43.2,12.2c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1C41.8,9,43.2,10.4,43.2,12.2L43.2,12.2z"/>
                        </g>
                        </svg>
                      </a>
                      <a href="https://vimeo.com/hanusek" target="_blank" rel="noopener noreferrer">
                        <svg width="59.2px" height="47.7px" viewBox="0 0 59.2 47.7">
                        <g>
                          <g>
                            <path  d="M50.5,0c5.7,0.2,8.6,3.4,8.6,9.8c0,0.4,0,0.9,0,1.3c-0.3,5.4-4.3,12.7-12,22c-8,9.7-14.8,14.6-20.4,14.6
                              c-3.5,0-6.4-3-8.7-8.9l-2.4-8.2l-2.4-8.2c-1.8-5.9-3.7-8.9-5.7-8.9c-0.4,0-2,0.9-4.6,2.6L0,12.8l4.4-3.6l4.3-3.6
                              c3.8-3.1,6.8-4.7,8.8-5c0.3,0,0.5,0,0.8,0c4.1,0,6.7,3,7.7,8.9c0.6,3.4,1,6.1,1.4,8.2c0.4,2.1,0.7,3.6,0.9,4.4
                              c1.4,5.6,2.8,8.4,4.4,8.4c1.2,0,3.1-1.8,5.6-5.5c2.5-3.7,3.8-6.5,3.9-8.3c0.1-0.4,0.1-0.7,0.1-1c0-2.4-1.3-3.7-4-3.7
                              c-1.4,0-2.8,0.3-4.4,0.8C36.6,4.3,42,0,49.9,0C50.1,0,50.3,0,50.5,0z"/></g>
                        </g>
                        </svg>
                      </a>
                    </div>
                  </div>
                  {/*}
                  <div className={'form ' + ((this.state.formOpen === true) ? 'open' : '')}>
                    <form name="contact" method="POST" data-netlify="true" action="/#contact?submit=true">
                      <div class="inputs">
                        <p>
                          <input type="text" name="name" placeholder="Name" /> 
                        </p>
                        <p>
                          <input type="email" name="email" placeholder="Email" />
                        </p>
                        <p>
                          <textarea name="message" placeholder="Message"></textarea>
                        </p>
                      </div>
                      <button type="submit">Send</button>
                    </form>
                    <button className="say" onClick={this.openForm} >Say Something</button>
                  </div>
                {*/}
                </div>
                <div className="section afterdark">
                </div>
                <div className="section afterdark mylogos">
                  <div className="text">
                    <h3>projects in development and on ice</h3>
                    <p className="space-below">here are a few of my pet projects I work on late at night when the mods are asleep.</p>
                  </div>
                  <div className="row">
                    <div className="col">
                      <a href="https://jcnh74.github.io/gmixr/" target="_blank" rel="noopener noreferrer" >
                        <img src={gmixr} alt="gmixr logo" />
                        <div className="text">
                          <div>
                            <h3>gmixr: ios app</h3>
                            <p>was on the apple app store for short time in 2016-17. link your spotify and play your playlists along with custom visualization based on search related animated gifs.</p>
                          </div>
                          <div className="corner-ribbon onice">On Ice</div>

                        </div>
                      </a>
                    </div>
                    <div className="col">
                      <a href="https://squeeze.watch" target="_blank" rel="noopener noreferrer" >
                        <img src={squeezewatch} alt="squeezewatch logo" />
                        <div className="text">
                          <div>
                            <h3>squeeze.watch: progressive web app</h3>
                            <p>time series forcasting with crypto currency trade data. beautiful visualiztions of trading indicators.</p>
                          </div>
                          <div className="corner-ribbon live">Live</div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <a href="https://agitated-torvalds-2185b1.netlify.com" target="_blank" rel="noopener noreferrer" >
                        <img src={trample} alt="trample logo" />
                        <div className="text">
                          <div>
                            <h3>trample the earth: progrssive web app</h3>
                            <p>currently on ice and in early development. a friends pet project in protoyping mode.</p>
                          </div>
                          <div className="corner-ribbon onice">On Ice</div>
                        </div>
                      </a>
                    </div>
                    <div className="col">
                      <a href="https://whenwaves.com" target="_blank" rel="noopener noreferrer" >
                        <img src={whenwaves} alt="whenwaves logo" />
                        <div className="text">
                          <div>
                            <h3>whenwaves.com: progressive web app</h3>
                            <p>think 24/7 surf film festival</p>
                          </div>
                          <div className="corner-ribbon live">Live</div>
                        </div>
                      </a>
                    </div>
                    <div className="col">
                      <a href="https://open.spotify.com/artist/2IqIO8PHTZuvy14ZaBwKRv?si=8FKH59LQTKW4Su0B_r2tSA" target="_blank" rel="noopener noreferrer" >
                        <img src={ostfap} alt="ostfap logo" />
                        <div className="text">
                          <div>
                            <h3>ost/fap: music project</h3>
                            <p>from time to time i like to play with sounds and organize them to fit a pattern.</p>
                          </div>
                          <div className="corner-ribbon live">Live</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="section afterdark video">
                  <div>
                    <div className="text">
                      <h3>3D Experiments</h3>
                      <p>When I sleep I put my computer to work rendering all sorts of nonsense.</p>
                    </div>
                    <video controls="true" poster={videoposter}>
                      <source src="https://www.dropbox.com/s/bya8qwttmxu1h80/3d-experiments-reel.mov?raw=1" />
                    </video>
                  </div>
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>
    );
  }
}

export default App;
